import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { withStyles } from "@mui/styles";
import classNames from "classnames";
import Drawer from "@mui/material/Drawer";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Icon from "@mui/material/Icon";
import Typography from "@mui/material/Typography";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { withRouter } from "react-router";
import { userActions } from "../actions";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import { menuIcons } from "../assets";
import { pageLoadTime } from "../utils/Helper";
// import {
//   getAvaliableProductBenefits,
//   isGrabGroupEmployee
// } from "selectors/CommonSelectors";

class MiniLeftDrawer extends Component {
  constructor(props) {
    super(props);
    let { routes } = props;
    this.routes = routes;

    this.state = {};
  }

  onLogoutClick = () => {
    let pathname = window.location.origin;
    this.props.logout();
    this.props.history.push("/");
    pageLoadTime(pathname);
  };

  renderIcon = menu => {
    const { classes } = this.props;
    return menu.icon !== undefined ? (
      <Icon>{menu.icon}</Icon>
    ) : (
      <Avatar
        src={menu.image}
        alt={menu.name}
        className={classes.iconImageWrapper}
        imgProps={{ className: classes.iconImage }}
      />
    );
  };

  render() {
    const { user, drawerOpen, classes, location, intl, theme } = this.props;
    const { listBenefits } = this.state;
    const filteredRoute = this.routes.filter(
      route =>
        user.auth.scope.includes(route.access) || route.access === undefined
    );

    const userAvatar = (
      <div
        className={classNames(
          classes.profile,
          !drawerOpen && classes.profileClose
        )}
      >
        <Typography className={classes.profileText}>{user.fullName}</Typography>
      </div>
    );

    return (
      <Drawer
        variant="permanent"
        classes={{
          paper: classNames(
            classes.drawerPaper,
            !drawerOpen && classes.drawerPaperClose
          )
        }}
        open={drawerOpen}
      >
        <div className={classes.drawerInner}>
          <MenuList>
            <MenuItem onClick={this.props.handleMenuToggle}>
              <div
                className={classNames(
                  classes.drawerHeader,
                  !drawerOpen && classes.drawerHeaderClose
                )}
              >
                <ListItemText
                  className={classNames(
                    !drawerOpen && classes.drawerTitleClose
                  )}
                />
                {!drawerOpen ? <ChevronRight /> : <ChevronLeft />}
              </div>
            </MenuItem>
          </MenuList>

          {userAvatar}

          <MenuList style={{ marginTop: 20 }}>
            {filteredRoute.map((child, index) => {
              if (child.menu && child.menu.show) {
                return (
                  <Link
                    key={index}
                    to={child.path}
                    className={classes.menuItem}
                  >
                    <Tooltip
                      title={child.name}
                      placement="right"
                      PopperProps={{ style: { pointerEvents: "none" } }}
                      disableHoverListener={drawerOpen}
                    >
                      <MenuItem
                        className={classNames(
                          classes.menuItemInner,
                          location.pathname === child.path ||
                            (location.pathname.includes("dashboard") &&
                              child.path.includes("dashboard"))
                            ? classes.menuItemActive
                            : ""
                        )}
                      >
                        <ListItemIcon className={classes.icon}>
                          {this.renderIcon(child.menu)}
                        </ListItemIcon>

                        <ListItemText
                          primary={child.name}
                          classes={{ primary: classes.itemText }}
                          className={classes.itemTextWrapper}
                        />
                      </MenuItem>
                    </Tooltip>
                  </Link>
                );
              } else return null;
            })}
            <span onClick={this.onLogoutClick} className={classes.menuItem}>
              <Tooltip
                title="Log Out"
                placement="right"
                PopperProps={{ style: { pointerEvents: "none" } }}
                disableHoverListener={drawerOpen}
              >
                <MenuItem className={classes.menuItemInner}>
                  <ListItemIcon className={classes.icon}>
                    <Avatar
                      src={menuIcons.logout}
                      alt="logout"
                      className={classes.iconImageWrapper}
                      imgProps={{ className: classes.iconImage }}
                    />
                    {/* <Icon>{menuIcons.learn}</Icon> */}
                  </ListItemIcon>
                  <ListItemText
                    primary="Log Out"
                    classes={{ primary: classes.itemText }}
                    className={classes.itemTextWrapper}
                  />
                </MenuItem>
              </Tooltip>
            </span>
          </MenuList>
        </div>

        <div style={{ flexGrow: 1 }} />
        {drawerOpen && (
          <div className={classes.infoContainer}>
            <Grid container spacing={"16px"}>
              <Grid item sm={12} md={12}>
                <Typography component={"span"} className={classes.bottomHeader}>
                  <b>For any clarifications</b>
                </Typography>
              </Grid>
              <Grid item sm={12} md={12}>
                <Grid container>
                  <Grid item sm={2}>
                    <img
                      className={classes.socialMedia}
                      src={menuIcons.contacts}
                      alt="contact us"
                    />
                  </Grid>
                  <Grid item sm={10}>
                    <Typography
                      component={"div"}
                      className={classes.bottomText}
                    >
                      <b>Contact us</b>
                    </Typography>
                    <Typography
                      component={"div"}
                      className={classes.bottomLight}
                    >
                      <a
                        href={`mailto:${user.client_name.config.supportEmail}`}
                        style={{ color: "#6B6A6D" }}
                      >
                        {user.client_name.config.supportEmail}
                      </a>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* <Grid item sm={12} md={12}>
                <Grid container>
                  <Grid item sm={2}>
                   <img className={classes.socialMedia} src={menuIcons.readBenefits} alt="read benefits" />
                  </Grid>
                  <Grid item sm={10}>
                    <Typography component={'span'} className={classes.bottomText}>
                      <b>Read your benefits</b>
                    </Typography>
                    <Typography component={'span'} className={classes.bottomLight}>
                    <a href={intl.formatMessage({ id: "enrolment.url.benefits" })} target="_blank" style={{color: '#6B6A6D'}}>  </a>                                                                
                    </Typography>
                  </Grid>
                </Grid>
              
              </Grid>
              <Grid item sm={12} md={12}>
                <Grid container>
                  <Grid item sm={2}>
                    <img className={classes.socialMedia}  src={menuIcons.learn} alt="learn more"/>
                  </Grid>
                  <Grid item sm={10}>
                    <Typography component={'span'} className={classes.bottomText}>
                      <b>Learn more</b>                        
                    </Typography>
                    <Typography component={'span'} className={classes.bottomLight}>
                      <a href={intl.formatMessage({ id: "enrolment.url.faq" })} target="_blank" style={{color: '#6B6A6D'}}>  </a>                                           
                    </Typography>
                  </Grid>
                </Grid>             
              </Grid> */}
            </Grid>
            {/* <Paper className={classes.paperRoot} elevation={0}>
              <div className={classes.callContent}>
                <Button
                  href="tel:1800 835 9733"
                  variant="fab"
                  color="primary"
                  aria-label="add"
                >
                  <Icon>phone</Icon>
                </Button>
                <div className={classes.callInfo}>
                  <Typography className={classes.helpText}>
                    Call Our Helpline
                  </Typography>
                  <a
                    href="tel:1800 835 9733"
                    style={{ textDecoration: "none" }}
                  >
                    <Typography
                      href="tel:1800 835 9733"
                      color="primary"
                      className={classes.phoneText}
                    >
                      1800 835 9733
                    </Typography>
                    <Typography className={classes.helpText}>
                      (Mon-Fri, 8:30am to 5:30pm)
                    </Typography>
                  </a>
                  <a
                    href="tel:+65 6233 3299"
                    style={{ textDecoration: "none" }}
                  >
                    <Typography
                      href="tel:+65 6233 3299"
                      color="primary"
                      className={classes.phoneText}
                    >
                      +65 6233 3299
                    </Typography>
                    <Typography className={classes.helpText}>
                      (if you're dialling from overseas)
                    </Typography>
                  </a>
                </div>
              </div>
              <div className={classes.policyContainer}>
                <Typography component="p" className={classes.policyText}>
                  POLICY NUMBER:{" "}
                </Typography>
                {user.policy &&
                  user.policy.policy_no && (
                    <Typography component="p" className={classes.policyNumber}>
                      {user.policy.policy_no}{" "}
                    </Typography>
                  )}
              </div>
            </Paper> */}
            {/* <Typography component="p" className={classes.tncBtn}>
              <a
                className={classes.tncBtn}
                href="/termsAndConditions"
                target="_blank"
              >
                Terms & Conditions
              </a>
            </Typography> */}
          </div>
        )}
        {/* <div style={{ height: 60 }} /> */}
      </Drawer>
    );
  }
}

const styles = theme => ({
  menuItem: {
    textDecoration: "none"
  },
  bottomHeader: {
    fontSize: "18px",
    lineHeight: "40px",
    color: "#000000"
  },
  bottomText: {
    fontSize: "16px",
    lineHeight: "24px",
    color: "#000000"
  },
  bottomLight: {
    fontSize: "13px",
    lineHeight: "24px",
    color: "#6B6A6D"
    //textDecorationLine: 'underline'
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#F2F2F2",
    padding: 16,
    width: theme.miniLeftDrawer.width
  },
  profile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    opacity: "1",
    maxHeight: 172,
    transition: "all 0.2s linear",
    width: theme.miniLeftDrawer.width
  },
  profileClose: {
    opacity: "0",
    maxHeight: 0
  },
  profileAvatar: {
    width: 80,
    height: 80
  },
  profileText: {
    textAlign: "left",
    fontSize: 24,
    padding: 10,
    width: "80%",
    fontWeight: "bold"
  },
  profileActions: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around"
  },
  drawerTitleClose: {
    display: "none"
  },
  drawerPaper: {
    position: "fixed",
    marginTop: `64px`,
    height: "calc(100% - 64px)",
    width: theme.miniLeftDrawer.width,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    overflow: "hidden"
  },
  drawerPaperClose: {
    width: 60,
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  drawerInner: {
    maxWidth: theme.miniLeftDrawer.width
  },
  drawerHeader: {
    minHeight: "auto",
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between"
  },
  drawerHeaderClose: {
    justifyContent: "flex-start"
  },
  menuItemAvatar: {
    width: 32,
    height: 32,
    maxHeight: 32,
    opacity: 1,
    transition: "all 0.2s ease-out"
  },
  showItem: {
    maxHeight: 24,
    opacity: 1
  },
  hideItem: {
    maxHeight: 0,
    opacity: 0
  },
  menuItemActive: {
    backgroundColor: "#f2f2f2 !important",
    borderRight: "7px solid red",
    fontWeight: 600
  },
  menuItemInner: {
    padding: theme.spacing(1.5, 2)
  },
  logout: {
    marginTop: 10,
    fontWeight: "bold",
    width: 250,
    fontSize: 20
  },
  iconImageWrapper: {
    overflow: "unset",
    width: "unset",
    height: "unset"
  },
  iconImage: {
    width: 24,
    height: 25
  },
  itemText: {
    color: "#6B6A6D",
    fontSize: "18px !important",
    lineHeight: "18px"
  },
  itemTextWrapper: {
    padding: theme.spacing(0, 2)
  }
});

function mapStateToProps(state) {
  return {
    agentType: state.user.auth.pcode,
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch(userActions.logout())
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(injectIntl(withStyles(styles, { withTheme: true })(MiniLeftDrawer)))
);
